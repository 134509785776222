import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SensitiveDataToggleEffects } from './sensitive-data-toggle.effects';
import { SensitiveDataToggleAdapter } from './sensitive-data-toggle-adapter.interface';
import { SENSITIVE_DATA_TOGGLE_ADAPTER } from './sensitive-data-toggle-adapter.token';
import { sensitiveDataFeature } from './sensitive-data-toggle.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(sensitiveDataFeature), EffectsModule.forFeature([SensitiveDataToggleEffects])],
})
export class SensitiveDataToggleStateModule {
  static withDataAdapter<Class extends SensitiveDataToggleAdapter>(
    dataAdapterClass: Type<Class>,
  ): ModuleWithProviders<SensitiveDataToggleStateModule> {
    return {
      ngModule: SensitiveDataToggleStateModule,
      providers: [{ provide: SENSITIVE_DATA_TOGGLE_ADAPTER, useClass: dataAdapterClass }],
    };
  }
}
