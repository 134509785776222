import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { apiDataAdapterBaseUrlInterceptorFactory, ApiDataAdapterModuleConfig } from '@ciphr/utils/data-adapters';

import { PersonalDetailsApiDataAdapterService } from './personal-details-api-data-adapter.service';

@NgModule({ providers: [PersonalDetailsApiDataAdapterService] })
export class PersonalDetailsApiDataAdapterModule {
  static withConfig({ apiConfig, interceptors }: ApiDataAdapterModuleConfig): ModuleWithProviders<PersonalDetailsApiDataAdapterModule> {
    return {
      ngModule: PersonalDetailsApiDataAdapterModule,
      providers: [
        provideHttpClient(
          withInterceptors([apiDataAdapterBaseUrlInterceptorFactory(apiConfig), ...(interceptors ?? [])]),
          withInterceptorsFromDi(),
        ),
      ],
    };
  }
}
