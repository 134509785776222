import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiDataAdapterModuleConfig, provideIsolatedHttpApiClient } from '@ciphr/utils/data-adapters';

import { AuthContextApiDataAdapterService } from './auth-context-api-data-adapter.service';
import { AUTH_CONTEXT_HTTP_CLIENT } from './auth-context-http-client.token';

@NgModule({
  providers: [AuthContextApiDataAdapterService],
})
export class AuthContextApiDataAdapterModule {
  static withConfig({ apiConfig, interceptors }: ApiDataAdapterModuleConfig): ModuleWithProviders<AuthContextApiDataAdapterModule> {
    return {
      ngModule: AuthContextApiDataAdapterModule,
      providers: [provideIsolatedHttpApiClient({ apiConfig, interceptors, token: AUTH_CONTEXT_HTTP_CLIENT })],
    };
  }
}
