@if (pagination.length > 1) {
  <div class="pagination">
    <button mat-button class="icon previous" (click)="previousPage()" [disabled]="isFirstPage">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="pages">
      @for (page of pagination; track page) {
        @if (page.ellipse) {
          <span class="ellipse">...</span>
        }
        @if (page.value) {
          <button mat-button class="page" [class.current]="page.value === pageIndex + 1" (click)="goToPage(page.value)">
            {{ page.value }}
          </button>
        }
      }
    </div>
    <button mat-button class="icon next" (click)="nextPage()" [disabled]="isLastPage">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
}
