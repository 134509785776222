import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAccessItems } from '@ciphr/shared/access-management/state';
import { filter, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccessManagementService {
  private store = inject(Store);
  verifyAccess(scopes: string[]): Observable<boolean> {
    return this.store.select(selectAccessItems).pipe(
      filter((accessItems) => !!accessItems.length),
      map((accessItems) => accessItems.filter((accessItem) => scopes.includes(accessItem)).length > 0),
    );
  }
}
