import { ErrorHandler, ModuleWithProviders, NgModule, Type } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ERROR_HANDLING_CLASS } from '@ciphr/utils/error-handling';

import { AUTH_CONTEXT_DATA_ADAPTER } from './auth-context-data-adapter.token';
import { AuthContextDataAdapter } from './auth-context-data-adapter.interface';
import { AuthContextEffects } from './auth-context.effects';
import { authContextFeature } from './auth-context.reducer';

@NgModule({ imports: [EffectsModule.forFeature([AuthContextEffects]), StoreModule.forFeature(authContextFeature)] })
export class AuthContextStateModule {
  static withConfig<DataAdapterClass extends AuthContextDataAdapter, ErrorHandlerClass extends ErrorHandler>(
    dataAdapterClass: Type<DataAdapterClass>,
    errorHandlerClass: Type<ErrorHandlerClass>,
  ): ModuleWithProviders<AuthContextStateModule> {
    return {
      ngModule: AuthContextStateModule,
      providers: [
        { provide: AUTH_CONTEXT_DATA_ADAPTER, useClass: dataAdapterClass },
        { provide: ERROR_HANDLING_CLASS, useClass: errorHandlerClass },
      ],
    };
  }
}
