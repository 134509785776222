import { createReducer, on } from '@ngrx/store';
import { uiActions } from './ui.actions';

export interface UiState {
  sideNavOpened: boolean;
  pageContentVisible: boolean;
}

export const initialState: UiState = {
  sideNavOpened: true,
  pageContentVisible: true,
};

export const uiReducer = createReducer(
  initialState,
  on(uiActions.toggleSidenav, (state, { isOpen }) => ({
    ...state,
    sideNavOpened: isOpen,
  })),
  on(uiActions.hideContentAfterLogout, (state) => ({ ...state, pageContentVisible: false })),
);
