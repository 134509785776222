import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AccessManagementService } from '../access-management-service/access-management.service';

@Directive({
  selector: '[ciphrAccessManagement]',
  standalone: true,
})
export class AccessManagementDirective {
  private viewContainerRef = inject(ViewContainerRef);
  private templateRef = inject(TemplateRef);
  private accessManagementService = inject(AccessManagementService);

  @Input() set ciphrAccessManagement(accessItems: string[]) {
    this.accessManagementService.verifyAccess(accessItems).subscribe((hasAccess) => {
      if (hasAccess) {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}
