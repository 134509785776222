import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { PersonalDetails } from '@ciphr/domains/personal-details/models';
import { PersonalDetailsDataAdapter } from '@ciphr/domains/personal-details/state';

@Injectable()
export class PersonalDetailsApiDataAdapterService implements PersonalDetailsDataAdapter {
  private httpClient = inject(HttpClient);

  fetchPersonalDetails() {
    return this.httpClient.get<PersonalDetails>(`/employee-personal-details`);
  }
}
