import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ACCESS_MANAGEMENT_HTTP_CLIENT } from './access-management-isolated-client.token';

@Injectable()
export class AccessManagementApiDataAdapterService {
  private http = inject(ACCESS_MANAGEMENT_HTTP_CLIENT);

  fetchAccessManagementAccessItems(): Observable<string[]> {
    return this.http.get<string[]>('/authorization/access-items');
  }
}
