import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@ciphr/core/auth';
import { uiActions } from './ui.actions';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);

  changePageContentVisibility$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uiActions.hideContentAfterLogout),
        tap(() => this.authService.logout()),
      ),
    { dispatch: false },
  );
}
