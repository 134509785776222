import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { PageEvent } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

type PageItem = {
  value: number | undefined;
  ellipse: boolean;
};

@Component({
  selector: 'ciphr-pagination',
  standalone: true,
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  imports: [MatIconModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {
  pagination: PageItem[] = [];

  @Input() pageNeighbours = 1;

  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(index: number) {
    if (index !== this._pageIndex) {
      this._pageIndex = Math.max(coerceNumberProperty(index), 0);
      this.pagination = this.buildPagination();
    }
  }
  private _pageIndex = 0;

  @Input()
  get length(): number {
    return this._length;
  }
  set length(value: NumberInput) {
    this._length = coerceNumberProperty(value);
    this.pagination = this.buildPagination();
  }
  private _length = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: NumberInput) {
    if (this._pageSize !== value) {
      this._pageSize = Math.max(coerceNumberProperty(value), 0);
      this.pagination = this.buildPagination();
    }
  }
  private _pageSize = 0;

  @Output() pageChanged = new EventEmitter<PageEvent>();

  get isFirstPage(): boolean {
    return !this.hasPreviousPage();
  }

  get isLastPage(): boolean {
    return !this.hasNextPage();
  }

  ngOnInit(): void {
    this.pagination = this.buildPagination();
  }

  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex + 1;
    this._emitPageEvent(previousPageIndex);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;
    this._emitPageEvent(previousPageIndex);
  }

  goToPage(page: number): void {
    const previousPageIndex = this.pageIndex;
    this.pageIndex = page - 1;
    this._emitPageEvent(previousPageIndex);
  }

  hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }
    return Math.ceil(this._length / this._pageSize);
  }

  buildPagination(): PageItem[] {
    const totalPages = this.getNumberOfPages();

    const currentPage = this._pageIndex + 1;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      const ellipse: PageItem = {
        value: undefined,
        ellipse: true,
      };

      let pages = this.range(startPage, endPage);

      const hasLeftEllipse = startPage > 2;
      const hasRightEllipse = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      if (hasLeftEllipse && !hasRightEllipse) {
        const extraPages = this.range(startPage - spillOffset, startPage - 1);
        pages = [ellipse, ...extraPages, ...pages];
      } else if (!hasLeftEllipse && hasRightEllipse) {
        const extraPages = this.range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, ellipse];
      } else {
        pages = [ellipse, ...pages, ellipse];
      }

      return [{ value: 1, ellipse: false }, ...pages, { value: totalPages, ellipse: false }];
    }

    return this.range(1, totalPages);
  }

  private _emitPageEvent(previousPageIndex: number): void {
    this.pageChanged.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }

  private range = (from: number, to: number, step = 1): PageItem[] => {
    let i = from;
    const range: PageItem[] = [];

    while (i <= to) {
      range.push({
        value: i,
        ellipse: false,
      });
      i += step;
    }

    return range;
  };
}
