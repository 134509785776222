import { createFeature, createReducer, on } from '@ngrx/store';
import { accessManagementDataAdapterActions } from './actions/access-management-data-adapter.actions';

export interface AccessManagementState {
  accessItems: string[];
}

export const initialState: AccessManagementState = {
  accessItems: [],
};

export const accessManagementFeature = createFeature({
  name: 'accessManagement',
  reducer: createReducer(
    initialState,
    on(accessManagementDataAdapterActions.accessItemsLoadedSuccessfully, (state, { accessItems }) => ({
      ...state,
      accessItems,
    })),
  ),
});
