import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiDataAdapterModuleConfig, provideIsolatedHttpApiClient } from '@ciphr/utils/data-adapters';

import { ACCESS_MANAGEMENT_HTTP_CLIENT } from './access-management-isolated-client.token';
import { AccessManagementApiDataAdapterService } from './access-management-api-data-adapter.service';

@NgModule({ providers: [AccessManagementApiDataAdapterService] })
export class AccessManagementApiDataAdapterModule {
  static withConfig({ apiConfig, interceptors }: ApiDataAdapterModuleConfig): ModuleWithProviders<AccessManagementApiDataAdapterModule> {
    return {
      ngModule: AccessManagementApiDataAdapterModule,
      providers: [provideIsolatedHttpApiClient({ apiConfig, interceptors, token: ACCESS_MANAGEMENT_HTTP_CLIENT })],
    };
  }
}
