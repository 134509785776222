import { HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, Observable } from 'rxjs';

import { OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class OAuthInterceptor implements HttpInterceptor {
  private readonly authStorage = inject(OAuthStorage);
  private readonly errorHandler = inject(OAuthResourceServerErrorHandler);

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authStorage.getItem('access_token');

    const header = 'Bearer ' + token;

    const headers = req.headers.set('Authorization', header);

    req = req.clone({ headers });

    return next.handle(req).pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}

export const oAuthInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authStorage = inject(OAuthStorage);
  const errorHandler = inject(OAuthResourceServerErrorHandler);

  const token = authStorage.getItem('access_token');
  const header = 'Bearer ' + token;
  const headers = req.headers.set('Authorization', header);

  return next(req.clone({ headers })).pipe(catchError((err) => errorHandler.handleError(err)));
};
