import { ChangeDetectionStrategy, Component, ElementRef, inject, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'ciphr-loading-spinner',
  standalone: true,
  template: '<mat-spinner></mat-spinner>',
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: var(--loading-spinner-overlay-background);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinnerModule],
})
export class LoadingSpinnerComponent implements OnInit {
  private readonly host = inject(ElementRef<HTMLElement>);

  overlayBackground: string | undefined;

  ngOnInit(): void {
    const host = this.host.nativeElement;
    if (this.overlayBackground) {
      host.style.setProperty('--loading-spinner-overlay-background', this.overlayBackground);
    }
  }
}
