import { APP_INITIALIZER, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { filter, first, switchMap, tap } from 'rxjs';

import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { APP_CONFIG } from '@ciphr/core/app-config';

import { AuthService } from './auth.service';
import { AppPrefixAuthStorage } from './app-prefix-auth-storage';

const authInitializerFactory = (config?: AuthConfig) => () => {
  const appConfigSource = inject(APP_CONFIG);
  const authService = inject(AuthService);

  return () =>
    appConfigSource.pipe(
      filter(Boolean),
      tap(({ clientId }) => {
        authService.configure({
          ...config,
          clientId,
        });
        authService.initLogin();
      }),
      switchMap(() => authService.signedIn$),
      filter(Boolean),
      first(),
    );
};

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
})
export class AuthModule {
  static withConfig(appName: string, config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: OAuthStorage, useFactory: () => new AppPrefixAuthStorage(appName) },
        { provide: APP_INITIALIZER, useFactory: authInitializerFactory(config), multi: true },
      ],
    };
  }
}
