import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { sensitiveDataToggleActions } from '@ciphr/shared/sensitive-data-toggle/state';
import { selectActiveContext } from '@ciphr/core/auth-context/state';
import { filter } from 'rxjs';
import { accessManagementFeatureActions } from '@ciphr/shared/access-management/state';
import { selectPageContentVisibility } from './core/state/ui/ui.selectors';

@Component({
  selector: 'mypay-root',
  templateUrl: './app.component.html',
  styles: [
    `
      mat-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  private store = inject(Store);

  isPageContentVisible$ = this.store.select(selectPageContentVisibility);

  ngOnInit(): void {
    this.store.dispatch(sensitiveDataToggleActions.loadSensitiveDataOption());
    this.store
      .select(selectActiveContext)
      .pipe(filter(Boolean))
      .subscribe(() => this.store.dispatch(accessManagementFeatureActions.loadAccessItems()));
  }
}
