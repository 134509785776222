import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { Store } from '@ngrx/store';

import { AuthContextSelectorComponent } from '@ciphr/core/auth-context/features';

import { AuthPanelComponent } from '../auth-panel/auth-panel.component';
import { uiActions } from '../core/state/ui/ui.actions';

@Component({
  selector: 'mypay-header',
  standalone: true,
  imports: [AsyncPipe, MatButtonModule, MatIconModule, RouterLink, AuthContextSelectorComponent, AuthPanelComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(private store: Store) {}

  openSidenav(): void {
    this.store.dispatch(uiActions.toggleSidenav(true));
  }
}
