@if (hasMultipleContext$ | async) {
  <mat-form-field appearance="outline">
    <mat-label>Company context</mat-label>
    <mat-select data-test-id="companyContextSwitch" [value]="activeContext$ | async" (selectionChange)="selectActiveContext($event)">
      @for (context of contexts$ | async; track context) {
        <mat-option data-test-id="companyContextOption" [value]="context">{{
          context | authContextLabel
        }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
}
