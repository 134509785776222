import { createSelector } from '@ngrx/store';

import { authContextFeature } from './auth-context.reducer';

export const { selectActiveContext, selectAuthContextState } = authContextFeature;

export const selectAvailableContexts = createSelector(selectAuthContextState, ({ contextsList, managerContextsOnly }) => {
  return managerContextsOnly ? contextsList.filter((context) => !!context.adminUserLoginName) : contextsList;
});

export const selectAvailableContextsAmount = createSelector(selectAvailableContexts, (contexts) => contexts.length);
