import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideRouter, withRouterConfig } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { appConfigInitializerFactory } from '@ciphr/core/app-config';
import { AuthContextApiDataAdapterModule, AuthContextApiDataAdapterService } from '@ciphr/core/auth-context/api-data-adapter';
import { AuthContextStateModule } from '@ciphr/core/auth-context/state';
import { AuthModule, OAuthInterceptor } from '@ciphr/core/auth';
import { SensitiveDataToggleFeatureModule } from '@ciphr/shared/sensitive-data-toggle/features';
import { SensitiveDataToggleLocalStorageAdapterService } from '@ciphr/shared/sensitive-data-toggle/local-storage-data-adapter';
import { SensitiveDataToggleStateModule } from '@ciphr/shared/sensitive-data-toggle/state';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { HeaderComponent } from './header/header.component';
import { MyPayErrorHandler } from './core/error-handler';
import { NavigationComponent } from './navigation/navigation.component';
import { uiReducer } from './core/state/ui/ui.reducer';
import {
  AccessManagementApiDataAdapterModule,
  AccessManagementApiDataAdapterService,
} from '@ciphr/shared/access-management/api-data-adapter';
import { authContextInterceptor } from '@ciphr/core/auth-context/features';
import { AccessManagementStateModule } from '@ciphr/shared/access-management/state';
import { ReloadComponent } from './reload/reload.component';
import { UiEffects } from './core/state/ui/ui.effects';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    StoreModule.forRoot({ ui: uiReducer }),
    EffectsModule.forRoot([UiEffects]),
    AuthContextApiDataAdapterModule.withConfig({ apiConfig: { name: 'mypay', version: 1 } }),
    AuthContextStateModule.withConfig(AuthContextApiDataAdapterService, MyPayErrorHandler),
    AccessManagementApiDataAdapterModule.withConfig({ apiConfig: { name: 'mypay', version: 1 }, interceptors: [authContextInterceptor] }),
    AccessManagementStateModule.withConfig(AccessManagementApiDataAdapterService, MyPayErrorHandler),
    AuthModule.withConfig('mypay', {
      issuer: isDevMode() ? 'https://dev.ciphr.com' : window.location.origin,
      redirectUri: window.location.origin + '/mypay/app',
      responseType: 'code',
      scope: 'openid profile email offline_access https://api.mypay.ciphr.com/.default https://api.mypay-reporting.ciphr.com/.default',
      sessionChecksEnabled: true,
    }),
    HeaderComponent,
    NavigationComponent,
    ReloadComponent,
    SensitiveDataToggleFeatureModule,
    SensitiveDataToggleStateModule.withDataAdapter(SensitiveDataToggleLocalStorageAdapterService),
  ],
  providers: [
    provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload' })),
    { provide: APP_INITIALIZER, useFactory: appConfigInitializerFactory, multi: true },
    { provide: ErrorHandler, useClass: MyPayErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: { diameter: 40 } },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
