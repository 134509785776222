import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { first, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectNotificationState, selectSensitiveDataVisibility, sensitiveDataToggleActions } from '@ciphr/shared/sensitive-data-toggle/state';
import { SensitiveDataAlertComponent } from '../sensitive-data-alert/sensitive-data-alert.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'ciphr-sensitive-data-toggle-button',
  templateUrl: './sensitive-data-toggle-button.component.html',
  styleUrls: ['./sensitive-data-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensitiveDataToggleButtonComponent {
  private store = inject(Store);

  private dialog = inject(Dialog);

  selectSensitiveDataVisibility$ = this.store.select(selectNotificationState).pipe(
    tap((dismissed) => {
      if (!dismissed) {
        const dialogRef = this.dialog.open(SensitiveDataAlertComponent, {
          hasBackdrop: false,
          panelClass: 'sensitive-dialog',
        });
        dialogRef.closed.pipe(first()).subscribe(() => this.store.dispatch(sensitiveDataToggleActions.dismissNotification()));
      }
    }),
    switchMap(() => this.store.select(selectSensitiveDataVisibility)),
    first(),
  );

  toggleSensitiveData(): void {
    this.store.dispatch(sensitiveDataToggleActions.syncSensitiveData());
  }
}
