import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { SortOptionGroupComponent } from './sort-option-group.component';
import { SortAscOptionComponent } from './sort-asc-option.component';
import { SortDescOptionComponent } from './sort-desc-option.component';

@NgModule({
  declarations: [SortOptionGroupComponent, SortAscOptionComponent, SortDescOptionComponent],
  exports: [SortOptionGroupComponent, SortAscOptionComponent, SortDescOptionComponent],
  imports: [MatIconModule, NgIf],
})
export class SortToggleModule {}
