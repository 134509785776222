import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensitiveDataToggleButtonComponent } from './toggle-button/sensitive-data-toggle-button.component';
import { SensitiveDataAlertComponent } from './sensitive-data-alert/sensitive-data-alert.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule],
  declarations: [SensitiveDataToggleButtonComponent, SensitiveDataAlertComponent],
  exports: [SensitiveDataToggleButtonComponent],
})
export class SensitiveDataToggleFeatureModule {}
