import { ErrorHandler, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ERROR_HANDLING_CLASS } from '@ciphr/utils/error-handling';
import { AccessManagementDataAdapter } from './access-management-data-adapter.interface';
import { ACCESS_MANAGEMENT_DATA_ADAPTER } from './access-management-data-adapter.token';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { accessManagementFeature } from './access-management.reducer';
import { AccessManagementEffects } from './access-management.effects';

@NgModule({
  imports: [EffectsModule.forFeature([AccessManagementEffects]), StoreModule.forFeature(accessManagementFeature)],
})
export class AccessManagementStateModule {
  static withConfig<DataAdapterClass extends AccessManagementDataAdapter, ErrorHandlerClass extends ErrorHandler>(
    dataAdapterClass: Type<DataAdapterClass>,
    errorHandlerClass: Type<ErrorHandlerClass>,
  ): ModuleWithProviders<AccessManagementStateModule> {
    return {
      ngModule: AccessManagementStateModule,
      providers: [
        { provide: ACCESS_MANAGEMENT_DATA_ADAPTER, useClass: dataAdapterClass },
        { provide: ERROR_HANDLING_CLASS, useClass: errorHandlerClass },
      ],
    };
  }
}
