import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { selectSideNavOpened } from '../core/state/ui/ui.selectors';
import { AsyncPipe } from '@angular/common';
import { uiActions } from '../core/state/ui/ui.actions';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AuthPanelComponent } from '../auth-panel/auth-panel.component';
import { filter, map, pairwise, shareReplay, tap, withLatestFrom } from 'rxjs';
import { HeaderComponent } from '../header/header.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SensitiveDataToggleFeatureModule } from '@ciphr/shared/sensitive-data-toggle/features';
import { AccessManagementDirective } from '@ciphr/shared/access-management/features';
import { AccessItemsEnum } from '../core/access-items.enum';
import { selectAccessItems } from '@ciphr/shared/access-management/state';

@Component({
  selector: 'mypay-navigation',
  standalone: true,
  imports: [
    MatSidenavModule,
    RouterOutlet,
    MatListModule,
    RouterLink,
    AsyncPipe,
    MatIconModule,
    MatButtonModule,
    RouterLinkActive,
    AuthPanelComponent,
    HeaderComponent,
    MatSlideToggleModule,
    SensitiveDataToggleFeatureModule,
    AccessManagementDirective,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  readonly AccessItemsEnum = AccessItemsEnum;

  desktopView$ = this.breakpointObserver.observe([Breakpoints.Web]).pipe(
    tap(({ matches }) => this.store.dispatch(uiActions.toggleSidenav(matches))),
    map(({ matches }) => matches),
    shareReplay(),
  );
  mode$ = this.desktopView$.pipe(map((matches) => (matches ? 'side' : 'over')));
  opened$ = this.store.select(selectSideNavOpened);
  accessItems$ = this.store.select(selectAccessItems).pipe(
    pairwise(),
    tap(([prevAccessItems, currAccessItems]) => {
      if (
        prevAccessItems.length &&
        (prevAccessItems.length !== currAccessItems.length || !currAccessItems.every((el) => prevAccessItems.includes(el)))
      ) {
        this.router.navigate(['/reload']);
      }
    }),
    map(([, currAccessItems]) => currAccessItems),
  );
  constructor(
    private router: Router,
    private store: Store,
    private breakpointObserver: BreakpointObserver,
  ) {
    router.events
      .pipe(
        withLatestFrom(this.desktopView$),
        filter(([event, isDesktopView]) => event instanceof NavigationEnd && !isDesktopView),
      )
      .subscribe(() => this.closeNav());
  }

  closeNav(): void {
    this.store.dispatch(uiActions.toggleSidenav(false));
  }
}
