import { importProvidersFrom } from '@angular/core';
import { Route } from '@angular/router';

import { authContextInterceptor } from '@ciphr/core/auth-context/features';
import { canMatchAccessManagementGuard } from '@ciphr/shared/access-management/features';
import { canMatchAuthGuard } from '@ciphr/core/auth';
import {
  PersonalDetailsApiDataAdapterModule,
  PersonalDetailsApiDataAdapterService,
} from '@ciphr/domains/personal-details/api-data-adapter';
import { PersonalDetailsStateModule } from '@ciphr/domains/personal-details/state';

import { AccessItemsEnum } from './core/access-items.enum';
import { MyPayErrorHandler } from './core/error-handler';

export const appRoutes: Route[] = [
  {
    path: 'payslips',
    canMatch: [canMatchAuthGuard, canMatchAccessManagementGuard([AccessItemsEnum.Payslips])],
    loadChildren: () => import('./payslips/payslips.module').then((m) => m.PayslipsModule),
    title: 'MyPay - Payslips Page',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tax-docs',
    canMatch: [canMatchAuthGuard, canMatchAccessManagementGuard([AccessItemsEnum.TaxDocs])],
    loadChildren: () => import('./tax-docs/tax-docs.module').then((m) => m.TaxDocsModule),
    title: 'MyPay - Tax Documents Page',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'personal-details',
    canMatch: [canMatchAuthGuard, canMatchAccessManagementGuard([AccessItemsEnum.PersonalDetails])],
    loadComponent: () => import('@ciphr/domains/personal-details/features').then((m) => m.PersonalDetailsComponent),
    title: 'MyPay - Personal Details Page',
    providers: [
      importProvidersFrom([
        PersonalDetailsApiDataAdapterModule.withConfig({
          apiConfig: { name: 'mypay', version: 1 },
          interceptors: [authContextInterceptor],
        }),
        PersonalDetailsStateModule.withDataAdapter(PersonalDetailsApiDataAdapterService, MyPayErrorHandler),
      ]),
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'payroll-approval',
    canMatch: [canMatchAuthGuard, canMatchAccessManagementGuard([AccessItemsEnum.PayrollApproval])],
    loadChildren: () => import('./payroll-approval/payroll-approval.module').then((m) => m.PayrollApprovalModule),
    title: 'MyPay - Payroll Approval Page',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'reload',
    loadComponent: () => import('./reload/reload.component').then((c) => c.ReloadComponent),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'payslips',
  },
];
