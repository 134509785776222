<mat-sidenav-container
  class="sidenav-container"
  [hasBackdrop]="(desktopView$ | async) === false"
  (backdropClick)="closeNav()"
  [class.mat-drawer-desktop]="desktopView$ | async"
  >
  @if (accessItems$ | async) {
    <mat-sidenav [opened]="opened$ | async" [mode]="(mode$ | async) || 'side'">
      <div>
        <div class="mobile-header">
          <a routerLink="/"> <img src="assets/images/logo.svg" alt="MyPay Payroll Business Solutions " /> </a>
          <button
            mat-icon-button
            [autofocus]="false"
            aria-label="An button with two crosses lines icon to close mobile navigation"
            (click)="closeNav()"
            >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-nav-list>
          <a
            mat-list-item
            routerLink="/payslips"
            data-test-id="navigateToPayslips"
            routerLinkActive="active"
            *ciphrAccessManagement="[AccessItemsEnum.Payslips]"
            >Payslips</a
            >
            <a
              mat-list-item
              routerLink="/tax-docs"
              data-test-id="navigateToTaxDocs"
              routerLinkActive="active"
              *ciphrAccessManagement="[AccessItemsEnum.TaxDocs]"
              >Tax Documents</a
              >
              <a
                mat-list-item
                routerLink="/personal-details"
                data-test-id="navigateToPersonalDetails"
                routerLinkActive="active"
                *ciphrAccessManagement="[AccessItemsEnum.PersonalDetails]"
                >Personal Details</a
                >
                <a
                  mat-list-item
                  routerLink="/payroll-approval"
                  data-test-id="navigateToPayrollApproval"
                  routerLinkActive="active"
                  *ciphrAccessManagement="[AccessItemsEnum.PayrollApproval]"
                  >Payroll Approval</a
                  >
                  <a mat-list-item href="/mypay-reporting/app" target="_blank" *ciphrAccessManagement="[AccessItemsEnum.Reports]">Reports</a>
                </mat-nav-list>
                <div class="sensitive-toggle-container">
                  <p>Hide sensitive data</p>
                  <ciphr-sensitive-data-toggle-button data-test-id="sensitiveDataToggleButton"></ciphr-sensitive-data-toggle-button>
                </div>
              </div>
              <div class="bottom-nav">
                @if ((desktopView$ | async) === false) {
                  <mat-nav-list>
                    <a mat-list-item href="/">SSO Settings</a>
                  </mat-nav-list>
                }
                <mypay-auth-panel version="mobile"></mypay-auth-panel>
              </div>
            </mat-sidenav>
          }
          <mat-sidenav-content>
            <mypay-header></mypay-header>
            <div class="content">
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
