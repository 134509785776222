import { SnackBarTypeConfig } from './snack-bar-type-config.type';
import { SnackBarTypes } from './snack-bar-types.enum';

export const snackBarTypeConfigsMap: { [type in SnackBarTypes]: SnackBarTypeConfig } = {
  [SnackBarTypes.Error]: {
    class: 'error',
    color: 'warn',
    icon: 'cancel',
  },
  [SnackBarTypes.Success]: {
    class: 'success',
    color: 'primary',
    icon: 'check_circle',
  },
  [SnackBarTypes.Warning]: {
    class: 'warning',
    color: 'primary',
    icon: 'warning',
  },
};
