import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { ACCESS_MANAGEMENT_DATA_ADAPTER } from './access-management-data-adapter.token';
import { accessManagementFeatureActions } from './actions/access-management-feature.actions';
import { accessManagementDataAdapterActions } from './actions/access-management-data-adapter.actions';

@Injectable()
export class AccessManagementEffects {
  private actions$ = inject(Actions);
  private dataAdapter = inject(ACCESS_MANAGEMENT_DATA_ADAPTER);

  loadContextsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accessManagementFeatureActions.loadAccessItems),
      mergeMap(() =>
        this.dataAdapter
          .fetchAccessManagementAccessItems()
          .pipe(map((accessItems) => accessManagementDataAdapterActions.accessItemsLoadedSuccessfully({ accessItems }))),
      ),
    ),
  );
}
