import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackBarTypes } from './snack-bar-types.enum';
import { SnackBarComponent } from './snack-bar.component';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  private readonly snackBarDuration = 4000;

  constructor(private snackBar: MatSnackBar) {}

  error(message: string, action?: string): MatSnackBarRef<SnackBarComponent> {
    return this.openSnackbar(SnackBarTypes.Error, message, action);
  }

  success(message: string, action?: string): MatSnackBarRef<SnackBarComponent> {
    return this.openSnackbar(SnackBarTypes.Success, message, action);
  }

  warning(message: string, action?: string): MatSnackBarRef<SnackBarComponent> {
    return this.openSnackbar(SnackBarTypes.Warning, message, action);
  }

  private openSnackbar(type: SnackBarTypes, message: string, action?: string): MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      data: { action, message, type },
      panelClass: 'material-snackbar',
      duration: this.snackBarDuration,
    });
  }
}
