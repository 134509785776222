import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AuthContext } from '@ciphr/core/auth-context/models';

export const authContextFeatureActions = createActionGroup({
  source: 'Auth Context Feature',
  events: {
    'Load Contexts List': emptyProps(),
    'Select Active Context': props<{ context: AuthContext }>(),
    'Select Active Manager Context': props<{ context: AuthContext }>(),
    'Set Manager Contexts Only': emptyProps(),
  },
});
