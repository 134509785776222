<header>
  <a routerLink="/">
    <img src="assets/images/logo.svg" alt="MyPay Payroll Business Solutions" />
  </a>

  <ciphr-auth-context-selector></ciphr-auth-context-selector>

  <mypay-auth-panel></mypay-auth-panel>

  <button
    mat-icon-button
    class="nav-button"
    aria-label="Button with a hamburger icon to toggle navigation"
    data-test-id="navButton"
    (click)="openSidenav()"
  >
    <mat-icon>menu</mat-icon>
  </button>
</header>
