import { AsyncPipe } from '@angular/common';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

import { filter, first, map, Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import {
  authContextFeatureActions,
  selectActiveContext,
  selectAvailableContexts,
  selectAvailableContextsAmount,
} from '@ciphr/core/auth-context/state';

import { AuthContextLabelPipe } from './auth-context-label.pipe';
import { AuthContext } from '@ciphr/core/auth-context/models';

@Component({
  selector: 'ciphr-auth-context-selector',
  standalone: true,
  imports: [AsyncPipe, MatSelectModule, AuthContextLabelPipe],
  templateUrl: './auth-context-selector.component.html',
  styleUrls: ['./auth-context-selector.component.scss'],
})
export class AuthContextSelectorComponent implements OnInit {
  private store = inject(Store);

  @Input() set managerContextsOnly(value: BooleanInput) {
    this.useManagerContextsOnly = coerceBooleanProperty(value);
  }

  private useManagerContextsOnly = false;
  activeContext$ = this.store.select(selectActiveContext).pipe(filter(Boolean), first());
  contexts$: Observable<AuthContext[]> = this.store.select(selectAvailableContexts);
  hasMultipleContext$ = this.store.select(selectAvailableContextsAmount).pipe(map((amount) => amount > 1));

  ngOnInit(): void {
    this.useManagerContextsOnly && this.store.dispatch(authContextFeatureActions.setManagerContextsOnly());
    this.store.dispatch(authContextFeatureActions.loadContextsList());
  }

  selectActiveContext({ value }: MatSelectChange): void {
    value.adminUserLoginName === null
      ? this.store.dispatch(authContextFeatureActions.selectActiveContext({ context: value }))
      : this.store.dispatch(authContextFeatureActions.selectActiveManagerContext({ context: value }));
  }
}
