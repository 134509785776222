import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Route, Router } from '@angular/router';
import { inject } from '@angular/core';

import { tap } from 'rxjs';

import { AccessManagementService } from '../access-management-service/access-management.service';

export function canActivateAccessManagementGuard(accessItems: string[]): CanActivateFn {
  return (activatedRouteSnapshot: ActivatedRouteSnapshot) => verifyAccessGuard(accessItems, activatedRouteSnapshot?.routeConfig?.path);
}

export function canMatchAccessManagementGuard(accessItems: string[]): CanMatchFn {
  return (route: Route) => verifyAccessGuard(accessItems, route.path);
}

const verifyAccessGuard = (accessItems: string[], currentPath?: string) => {
  const accessManagementService = inject(AccessManagementService);
  const router = inject(Router);

  return accessManagementService.verifyAccess(accessItems).pipe(
    tap((hasAccess) => {
      if (!hasAccess) {
        const currentRouteIndex = router.config.findIndex((currRoute) => currRoute.path === currentPath);

        if (!currentRouteIndex) {
          router.navigate([router.config[0].path]);
        }
        const nextRoute = router.config[currentRouteIndex + 1];
        router.navigate([nextRoute.path]);
      }
    }),
  );
};
