<div class="alert-container" data-test-id="alertContainer">
  <button mat-icon-button (click)="dialogRef.close()" data-test-id="closeAlertContainerBtn"><mat-icon>close</mat-icon></button>
  <div class="inputs">
    <div class="input visible">
      <p>{{ 1402 | number : "1.2-2" }}</p>
    </div>
    <mat-icon>chevron_right</mat-icon>
    <div class="input hidden">
      @for (index of [0, 1, 2, 3, 4, 5]; track index) {
        <span></span>
      }
    </div>
  </div>
  <p class="text">
    We hide your sensitive data to protect your privacy.<br />
    For watching the data please turn off the Sensitive toggle button in menu.
  </p>
</div>
