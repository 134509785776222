import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  template: '<mat-spinner></mat-spinner>',
  standalone: true,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    `,
  ],
  imports: [MatProgressSpinnerModule],
})
export class ReloadComponent implements OnInit {
  private router = inject(Router);
  ngOnInit(): void {
    this.router.navigate(['/']);
  }
}
