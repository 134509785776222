import { ErrorHandler, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PERSONAL_DETAILS_DATA_ADAPTER } from './personal-details-data-adapter.token';
import { PersonalDetailsEffects } from './personal-details.effects';
import { personalDetailsFeature } from './personal-details.reducer';
import { PersonalDetailsDataAdapter } from './personal-details-data-adapter.interface';
import { ERROR_HANDLING_CLASS } from '@ciphr/utils/error-handling';

@NgModule({
  imports: [StoreModule.forFeature(personalDetailsFeature), EffectsModule.forFeature([PersonalDetailsEffects])],
})
export class PersonalDetailsStateModule {
  static withDataAdapter<Class extends PersonalDetailsDataAdapter, ErrorHandlerClass extends ErrorHandler>(
    dataAdapterClass: Type<Class>,
    errorHandlerClass: Type<ErrorHandlerClass>,
  ): ModuleWithProviders<PersonalDetailsStateModule> {
    return {
      ngModule: PersonalDetailsStateModule,
      providers: [
        { provide: ERROR_HANDLING_CLASS, useClass: errorHandlerClass },
        { provide: PERSONAL_DETAILS_DATA_ADAPTER, useClass: dataAdapterClass },
      ],
    };
  }
}
