import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'ciphr-sensitive-data-alert',
  templateUrl: './sensitive-data-alert.component.html',
  styleUrls: ['./sensitive-data-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensitiveDataAlertComponent {
  dialogRef = inject(DialogRef);
}
