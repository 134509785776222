import { createFeature, createReducer, on } from '@ngrx/store';

import { AuthContext } from '@ciphr/core/auth-context/models';

import { authContextDataAdapterActions } from './actions/auth-context-data-adapter.actions';
import { authContextFeatureActions } from './actions/auth-context-feature.actions';

export interface AuthContextState {
  activeContext: AuthContext | null;
  contextsList: AuthContext[];
  managerContextsOnly: boolean;
}

export const initialState: AuthContextState = {
  activeContext: null,
  contextsList: [],
  managerContextsOnly: false,
};

export const authContextFeature = createFeature({
  name: 'authContext',
  reducer: createReducer(
    initialState,
    on(authContextDataAdapterActions.contextsListLoadedSuccessfully, (state, { contextsList }) => {
      let activeContext = contextsList.find((context) => context.defaultContext) || null;

      if (state.managerContextsOnly && !activeContext?.adminUserLoginName) {
        activeContext = contextsList.find((context) => !!context.adminUserLoginName) || null;
      }

      return {
        ...state,
        activeContext,
        contextsList,
      };
    }),
    on(authContextFeatureActions.selectActiveContext, authContextFeatureActions.selectActiveManagerContext, (state, { context }) => ({
      ...state,
      activeContext: context,
    })),
    on(authContextFeatureActions.setManagerContextsOnly, (state) => ({
      ...state,
      managerContextsOnly: true,
    })),
  ),
});
